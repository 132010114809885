import React from "react";
import { Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import BottomBorder from "../bottomBorder";
import { getImageProps } from "helpers/getImgProps";
import * as styles from "./bowls.module.scss";
import placeholder from "images/placeholder.png";

const Bowls = ({ veganImg, bowlImg, bgBottom, bowlsList }) => {
  let vegan = false;

  const bowlList = bowlsList?.products.map(
    (
      {
        image_url: image,
        cal: kcal,
        description: title,
        product_ingredients: ingredients,
        price,
      },
      index
    ) => {
      const ingredientList = ingredients.map(({ name }, index) =>
        index === ingredients?.lnegth - 1
          ? `${name}`.toLowerCase()
          : `${name}, `.toLowerCase()
      );
      if (index % 2 !== 0) {
        return (
          <div key={index} className={`${styles.singleBowl} ${styles.web}`}>
            {vegan ? (
              <Col md={2}>
                <div className={styles.vegan}>
                  <div>
                    <GatsbyImage {...getImageProps(veganImg)} />
                  </div>
                </div>
              </Col>
            ) : (
              <Col md={2}>
                <div className={styles.vegan}></div>
              </Col>
            )}

            <Col md={5}>
              <div
                className={styles.productDetails}
                style={{
                  backgroundColor: vegan && "#098138",
                  color: vegan && "#ffffff",
                }}
              >
                <div
                  className={styles.name}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {title}
                </div>
                <div
                  className={styles.price}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {price}
                </div>
                <div
                  className={styles.ingredients}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {ingredientList}
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className={`${styles.imgPart}`}>
                <div className={styles.img}>
                  <img src={image} alt="text" />
                  <div
                    className={`${styles.cal} d-flex flex-column align-items-center`}
                  >
                    <p>
                      {kcal}
                      <br />
                      <span>kcal</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        );
      } else
        return (
          <div key={index} className={`${styles.singleBowl} ${styles.web}`}>
            <Col md={4}>
              <div className={`${styles.imgPart}`}>
                <div className={styles.img}>
                  {image ? (
                    <img src={image} alt="text" />
                  ) : (
                    <img src={placeholder} alt="placeholder" />
                  )}
                  <div
                    className={`${styles.cal} d-flex flex-column align-items-center`}
                  >
                    <p>
                      {kcal}
                      <br />
                      <span>kcal</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div
                className={styles.productDetails}
                style={{
                  backgroundColor: vegan && "#098138",
                  color: vegan && "#ffffff",
                }}
              >
                <div
                  className={styles.name}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {title}
                </div>
                <div
                  className={styles.price}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {price}
                </div>
                <div
                  className={styles.ingredients}
                  style={{
                    color: vegan && "#ffffff",
                  }}
                >
                  {ingredientList}
                </div>
              </div>
            </Col>
            {vegan ? (
              <Col md={2}>
                <div className={styles.vegan}>
                  <div>
                    <GatsbyImage {...getImageProps(veganImg)} />
                  </div>
                </div>
              </Col>
            ) : (
              <Col md={2}>
                <div className={styles.vegan}></div>
              </Col>
            )}
          </div>
        );
    }
  );
  const bowlListMobile = bowlsList?.products.map(
    (
      {
        image_url: image,
        cal: kcal,
        description: title,
        product_ingredients: ingredients,
        price,
      },
      index
    ) => {
      const ingredientList = ingredients.map(({ name }, index) =>
        index === ingredients?.lnegth - 1
          ? `${name}`.toLowerCase()
          : `${name}, `.toLowerCase()
      );
      return (
        <div key={index} className={`${styles.singleBowl} ${styles.mobile}`}>
          <Col sm={12}>
            <div className={`${styles.imgPart}`}>
              <div className={styles.img}>
                {image ? (
                  <img src={image} alt="text" />
                ) : (
                  <img src={placeholder} alt="placeholder" />
                )}
                <div
                  className={`${styles.cal} d-flex flex-column align-items-center`}
                >
                  <p>
                    {kcal}
                    <br />
                    <span>kcal</span>
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12}>
            <div
              className={styles.productDetails}
              style={{
                backgroundColor: vegan && "#098138",
                color: vegan && "#ffffff",
              }}
            >
              <div
                className={styles.name}
                style={{
                  color: vegan && "#ffffff",
                }}
              >
                {title}
              </div>
              <div
                className={styles.price}
                style={{
                  color: vegan && "#ffffff",
                }}
              >
                {price}
              </div>
              <div
                className={styles.ingredients}
                style={{
                  color: vegan && "#ffffff",
                }}
              >
                {ingredientList}
              </div>
            </div>
          </Col>
        </div>
      );
    }
  );

  return (
    <div className={styles.bowlsWrapper}>
      <div className={styles.title}>
        {bowlsList?.name}
        <BottomBorder />
      </div>
      <div className={styles.bowls}>
        {bowlList} {bowlListMobile}
      </div>
      <Col md={5}>
        <div
          className={styles.bowlImg}
          style={{
            left: bowlList.length % 2 === 0 ? "12%" : "40%",
          }}
        >
          <GatsbyImage {...getImageProps(bowlImg)} />
        </div>
      </Col>
      <div
        className={styles.bgBottom}
        style={{
          backgroundImage: "url(" + bgBottom.localFile.url + ")",
        }}
      ></div>
    </div>
  );
};

export default Bowls;
